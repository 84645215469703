
import { defineComponent, watch, reactive, onMounted } from 'vue'
import TabInfo from '@/components/home/TabInfo.vue'

import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'

import { getNewsApi } from '@/api/api'

export default defineComponent({
  name: 'LatestNews',
  components: {
    TabInfo
  },
  setup(props) {
    const router = useRouter()
    const store = useStore()

    const state = reactive<{
      list: any[]
    }>({
      list: []
    })

    onMounted(() => {
      getNews({
        articleModel: 2
      })
    })

    const handleDetail = (data: any) => {
      if (!data || !data.id) return false
      router.push({
        path: '/detail',
        query: {
          id: data.id
        }
      })
    }

    const handleMore = () => {
      store.commit('setCurrentActiveMenu', '行业动态')
      router.push({
        path: '/news'
      })
    }

    const getNews = async (params: { articleModel: number }) => {
      try {
        const result = await getNewsApi(params)
        if (result.status !== 200 || !result.data) return false
        const { code, msg, data } = result.data
        if (code !== 0) {
          console.log('报错了', msg)
          return false
        }
        let list: any[] = []
        if (data && data.length) {
          data.forEach((item: any, index: number) => {
            // let description = ''
            // if (index === 0) {
            //   description = item.description
            //     ? item.description.substring(0, 82) + '...'
            //     : item.description
            // } else if (index === 1) {
            //   description = item.description
            //     ? item.description.substring(0, 205) + '...'
            //     : item.description
            // } else {
            //   description = item.description
            //     ? item.description.substring(0, 20) + '...'
            //     : item.description
            // }
            list.push({
              id: item.id,
              title: item.title,
              description: item.description,
              coverImage: item.coverImage,
              createTime: item.createTime
                ? item.createTime.split(' ')[0]
                : item.createTime
            })
          })
        }
        state.list = list
      } catch (e) {
        console.log('出错', e)
      }
    }

    return {
      state,
      handleDetail,
      handleMore
    }
  }
})
