import { App, InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { PageEnum } from '@/utils/typeEnum'

interface CompanyInfo {
  name: string;
  mobile: string;
  phone: string;
  email: string;
  address: string;
  copyright: string;
  productCenterSummary: string;
  solutionSummary: string;
  businessAreaSummary: string;
  businessAreaSummaryHard: string;
  businessAreaSummaryIntegrate: string;
}

interface AppState {
  isHomeFirst: boolean,
  currentActiveMenu: string;
  companyInfo: CompanyInfo;
  categorys: any;
}

export const key: InjectionKey<Store<AppState>> = Symbol()

export const store = createStore<AppState>({
  state: {
    isHomeFirst: (sessionStorage.getItem('current_active_menu') || '首页') === '首页',
    currentActiveMenu: sessionStorage.getItem('current_active_menu') || '首页',
    companyInfo: {
      name: '四川润泽皓宇科技有限公司',
      mobile: '18080263344',
      phone: '028-15812548',
      email: 'runzehaoyu@runzehaoyu.com',
      address: '成都市武侯区天晖南街169号ACC中航城市广场A区1006',
      copyright: 'Copyright © 2022  四川润泽皓宇科技有限公司  蜀ICP备2022005652号',
      productCenterSummary: '产品中心介绍',
      solutionSummary: '产品方案介绍',
      businessAreaSummary: '软件领域介绍',
      businessAreaSummaryHard: '硬件领域介绍',
      businessAreaSummaryIntegrate: '集成领域介绍',
    },
    categorys:[]
  },
  getters: {
    // ctegoryId: (state: AppState) => {
    //   return state.currentActiveCategoryId;
    // }
  },
  mutations: {
    // setCurrentActiveCategoryId(state: AppState, currentActiveCategoryId: number) {
    //   state.currentActiveCategoryId = currentActiveCategoryId
    //   sessionStorage.setItem('current_active_category_id', currentActiveCategoryId + '')
    // },
    // setMenuList(state: AppState, menuList: MenuItem[]) {
    //   state.menuList = menuList
    //   sessionStorage.setItem('menu_list', JSON.stringify(menuList))
    // },
    // setCompanyInfo(state: AppState, companyInfo: any) {
    //   state.companyInfo = companyInfo
    //   sessionStorage.setItem('company_info', JSON.stringify(companyInfo))
    // },
    // setCodeUrl(state: AppState, codeUrl: string) {
    //   state.codeUrl = codeUrl
    //   sessionStorage.setItem('code_url', codeUrl)
    // },
    setIsHomeFirst(state: AppState, val: boolean) {
      state.isHomeFirst = val
    },
    setCategorys(state: AppState, val: any) {
      state.categorys = val
    },
    setCurrentActiveMenu(state: AppState, val: string) {
      state.currentActiveMenu = val
      sessionStorage.setItem('current_active_menu', val)
    },
  },
  actions: {
  },
  modules: {
  }
})

export const setupStore = (app: App) => {
  app.use(store, key)
}

export function useStore () {
  return baseUseStore(key)
}
