

import { defineComponent, watch, reactive, } from 'vue'

import { useStore } from '@/store/index'

export default defineComponent({
  name: 'CopyrightInfo',

  setup () {

    const store = useStore()
    const state = reactive<{
      companyInfo: any
    }>({
      companyInfo: {}
    })

    watch(
      () => store.state.companyInfo,
      (val) => {
        state.companyInfo = val
      },
      {
        immediate: true
      }
    )

    return {
      state,
    }
  }

})
