
import { shallowRef } from "@vue/reactivity";
import {
  defineComponent,
  ref,
  watch,
  reactive,
  onMounted,
  nextTick,
} from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { useRouter } from "vue-router";
import { useStore } from "@/store/index";

export default defineComponent({
  name: "Map",
  components: {},
  setup() {
    let map = shallowRef(null);

    const router = useRouter();
    const store = useStore();

    const state = reactive<{
      companyInfo: any;
    }>({
      companyInfo: {},
    });
    onMounted(() => {
      // nextTick(() => {
      //   initMap();
      // });
    });
    const initMap = () => {
      AMapLoader.load({
        key: "eb476c1df2a8cb1e3d31cbf1a62335ea", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          console.log("AMap", AMap);

          map = new AMap.Map("map-container", {
            //设置地图容器id
            viewMode: "", //是否为3D地图模式
            zoom: 5, //初始化地图级别
            center: [105.602725, 37.076636], //初始化地图中心点位置
          });
        })
        .catch((e) => {
          console.log(e);
        });
    };

    return {
      map,
    };
  },
});
