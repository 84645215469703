

import { defineComponent, PropType } from 'vue'

interface ClassifyInfo {
  code: string;
  label: string;
}

export default defineComponent({
  name: 'BannerClassify',
  props: {
    // 类型: 1: 产品中心, 2: 行业动态, 3: 关于我们, 4: 
    type: {
      type: String,
      default: '',
    },
    bannerUrl: {
      type: String,
      default: '',
    },
    activeCode: {
      type: String,
      default: '',
    },
    classifyList: {
      type: Array as PropType<ClassifyInfo[]>,
      default: () => ([])
    }
  },
  emits: ['onChangeActiveCode'],
  setup (props, { emit }) {

    const changeActiveCode = (activeCode: string) => {
      if (props.activeCode === activeCode) return false
      emit('onChangeActiveCode', activeCode)
    }

    return {
      changeActiveCode,
    }
  },
})
