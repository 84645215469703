
import { shallowRef } from '@vue/reactivity'
import { defineComponent, ref, watch, reactive } from 'vue'

import TabInfo from '@/components/home/TabInfo.vue'
import Map from '@/components/common/map.vue'

import { useRouter } from 'vue-router'
import { useStore } from '@/store/index'

export default defineComponent({
  name: 'AboutUs',
  components: {
    TabInfo,
    Map
  },
  setup() {
    const map = shallowRef(null)

    const router = useRouter()
    const store = useStore()

    const state = reactive<{
      companyInfo: any
    }>({
      companyInfo: {}
    })

    watch(
      () => store.state.companyInfo,
      (val) => {
        state.companyInfo = val
      },
      {
        immediate: true
      }
    )

    const handleAdd = () => {
      store.commit('setCurrentActiveMenu', '关于我们')
      router.push({
        path: '/about',
        query: {
          type: 1
        }
      })
    }

    const handleMore = () => {
      store.commit('setCurrentActiveMenu', '关于我们')
      router.push({
        path: '/about'
      })
    }

    return {
      state,
      map,
      handleAdd,
      handleMore
    }
  }
})
