
import { getListApi } from "@/api/api";

import { defineComponent, ref, watch, reactive, onMounted } from "vue";

import TabInfo from "@/components/home/TabInfo.vue";
import CardBannerList from "@/components/common/CardBannerList.vue";

import { useRouter } from "vue-router";
import { useStore } from "@/store/index";

export default defineComponent({
  name: "ProjectCase",
  components: {
    TabInfo,
    CardBannerList,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    const activeIndex = ref(1);

    const state = reactive<{
      companyInfo: any;
      companyList: any[];
      name: string;
    }>({
      companyInfo: "",
      companyList: [],
      name: "",
    });

    // watch(
    //   () => store.state.companyInfo,
    //   (val) => {
    //     state.companyInfo = val;
    //   },
    //   {
    //     immediate: true,
    //   }
    // );

    onMounted(() => {
      getList();
    });
    const onSwiper = (val: any) => {
      state.companyInfo = state.companyList[val].description;
      state.name = state.companyList[val].title;
    };
    const getList = async () => {
      try {
        const result = await getListApi({ articleModel: 3 });
        if (result.status !== 200 || !result.data) return false;
        const { code, msg, data } = result.data;
        state.companyList = data.rows || [];
        state.companyInfo = data.rows[0].description;
        state.name = data.rows[0].title;
        console.log("1", state.companyList);
      } catch (e) {}
    };

    const handleDetail = (id: string | number) => {
      if (!id) return false;
      router.push({
        path: "/detail?id=" + id,
      });
    };

    const handleMore = () => {
      store.commit("setCurrentActiveMenu", "项目案例");
      router.push({
        path: "/project",
      });
    };

    return {
      state,
      activeIndex,
      onSwiper,
      handleDetail,
      handleMore,
    };
  },
});
