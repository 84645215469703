
import { defineComponent } from 'vue'
import GoTop from '@/components/common/GoTop.vue'
import HeaderInfo from '@/components/common/HeaderInfo.vue'

export default defineComponent({
  components: {
    HeaderInfo,
    GoTop
  },
  setup() {
    const handleGoTop = () => {
      window.scrollTo(0, 0)
    }
    return {
      handleGoTop
    }
  }
})
