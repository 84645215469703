
import Map from '@/components/common/map.vue'
import { defineComponent, reactive, watch, } from 'vue'

import { useStore } from '@/store/index'


export default defineComponent ({
  components: { Map },
  name: 'Company',
  setup () {

    const store = useStore()

    const state = reactive<{
      companyInfo: any
    }>({
      companyInfo: {}
    })

    watch(
      () => store.state.companyInfo,
      (val) => {
        state.companyInfo = val
      },
      {
        immediate: true
      }
    )

    return {
      Map,
      state,
    }
  }
})

