
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
  watch
} from 'vue'

import { useStore } from '@/store/index'
import { useRoute } from 'vue-router'
import {
  getCompanyApi,
  getDetailApi,
  getListApi,
  getNewsApi,
  getCodeApi,
  getBannerApi,
  getJobsApi,
  getCategorysApi
} from '@/api/api'

import BannerClassify from '@/components/common/BannerClassify.vue'
import FooterInfo from '@/components/common/FooterInfo.vue'
import CopyrightInfo from '@/components/common/CopyrightInfo.vue'
import GoTop from '@/components/common/GoTop.vue'
import TabInfo from '@/components/home/TabInfo.vue'

import NewsInfo from '@/components/news/NewsInfo.vue'
// import ProductInfo from '@/components/product/ProductInfo.vue'
import RecruitInfo from '@/components/about/RecruitInfo.vue'
import CompanyInfo from '@/components/about/CompanyInfo.vue'
import WorkEnvironment from '@/components/about/WorkEnvironment.vue'
import ProductCenter from '@/components/home/ProductCenter.vue'
import LatestNews from '@/components/home/LatestNews.vue'
import SolutionInfo from '@/components/home/SolutionInfo.vue'
import ProjectCase from '@/components/home/projectCase.vue'
import AboutUs from '@/components/home/AboutUs.vue'
import BannerInfo from '@/components/home/BannerInfo.vue'

import Carousel from 'ant-design-vue/lib/carousel'
import 'ant-design-vue/lib/carousel/style/css'

export default defineComponent({
  name: 'Home',
  components: {
    BannerClassify,
    FooterInfo,
    CopyrightInfo,
    GoTop,
    NewsInfo,
    // ProductInfo,
    RecruitInfo,
    CompanyInfo,
    WorkEnvironment,
    ProductCenter,
    LatestNews,
    ProjectCase,
    SolutionInfo,
    AboutUs,
    BannerInfo,
    Carousel,
    TabInfo
  },
  setup() {
    const route = useRoute()
    // const router = useRouter();
    const store = useStore()

    const carouselRef = ref()

    const state = reactive<{
      current: number
      business: any
      productList: any[]
      newsList: any[]
      schemeList: any[]
    }>({
      business: [],
      current: 0,
      productList: [],
      newsList: [],
      schemeList: []
    })

    const getList = async () => {
      try {
        const obj = {
          articleModel: 4
        }
        const result = await getListApi(obj)
        if (result.status !== 200 || !result.data) return false
        const { code, msg, data } = result.data
        if (code !== 0) {
          return false
        }
      } catch (e) {
        console.log('出错', e)
      }
    }

    // getBanner()

    let time: number = new Date().getTime()
    watch(
      () => route.query.code,
      (val) => {
        carouselRef.value.goTo(0)
      },
      {
        // immediate: true
      }
    )

    watch(
      () => route.query.tab,
      (val) => {
        const index = Number(val) + 1

        carouselRef.value.goTo(index)
      },
      {
        // immediate: true
      }
    )

    onMounted(() => {
      // getCategorys();
      window.addEventListener('mousewheel', handleScroll, true)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('mousewheel', handleScroll)
    })

    const handleScroll = (e: any) => {
      // console.log('12',e)
      const currentTime = new Date().getTime()
      if (currentTime - time < 500) return false
      time = currentTime
      const direction = e.deltaY > 0 ? 'down' : 'up'
      if (direction === 'down') {
        if (state.current === 4) return false
        carouselRef.value.next()
      } else if (direction === 'up') {
        if (state.current === 0) return false
        carouselRef.value.prev()
      }
    }

    const handleGoTop = () => {
      carouselRef.value.goTo(0)
    }

    const onChange = (current: number) => {
      console.log('123', current)
      state.current = current
      store.commit('setIsHomeFirst', current === 0)
    }

    return {
      carouselRef,
      state,
      onChange,
      handleGoTop
    }
  }
})
