import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "main-content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderInfo = _resolveComponent("HeaderInfo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_GoTop = _resolveComponent("GoTop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderInfo, { class: "main-header-container" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    (_ctx.$route.path!=='/')
      ? (_openBlock(), _createBlock(_component_GoTop, {
          key: 0,
          class: "fixed",
          onOnGoTop: _ctx.handleGoTop
        }, null, 8, ["onOnGoTop"]))
      : _createCommentVNode("", true)
  ]))
}