import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/banner_up.png'
import _imports_1 from '@/assets/images/banner_down.png'


const _withScopeId = n => (_pushScopeId("data-v-2d1749ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner-container" }
const _hoisted_2 = { class: "arrow-item top" }
const _hoisted_3 = { class: "arrow-item bottom" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSlideSwiper('prev'))),
        class: "arrow-icon",
        src: _imports_0,
        alt: ""
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSlideSwiper('next'))),
        class: "arrow-icon",
        src: _imports_1,
        alt: ""
      })
    ]),
    (_ctx.list.length)
      ? (_openBlock(), _createBlock(_component_swiper, {
          key: 0,
          style: {"height":"100%"},
          "slides-per-view": 3,
          "space-between": 0,
          onSwiper: _ctx.onSwiper,
          onSlideChange: _ctx.onSlideChange,
          speed: 700,
          observer: true,
          allowTouchMove: false,
          lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 3,
      },
          centeredSlides: true,
          slidesOffsetBefore: 0,
          loop: true,
          autoplay: true,
          slidesPerView: 3,
          loopedSlides: 6,
          direction: "vertical"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "swiper-icon",
                    alt: "",
                    style: {"width":"100%","height":"100%"},
                    src: `${_ctx.state.path}${item.coverImage}`
                  }, null, 8, _hoisted_4)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["onSwiper", "onSlideChange"]))
      : _createCommentVNode("", true)
  ]))
}