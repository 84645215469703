// 基础接口地址
// export const baseApiUrl: string = 'http://rzhy.ywhkf.com/api'
// export const baseApiUrl: string = 'http://172.30.9.141:9000'
export const baseApiUrl: string = 'https://gwht.scrzhy.com'
// export const baseApiUrl: string = 'http://192.168.1.163:9000'
// 接口拼接地址
export const baseUrlPrefix: string = ''

// 用户api
export enum ApiConfig {
  // 获取网站栏目分类
  Categorys = '/api/categorys',
  // 获取企业信息
  Company = '/api/company/info',
  // 获取内容详情（包含动态、新闻、产品、产品方案、项目案例等所有内容详情）
  Detail = '/api/content/detail/',
  // 获取内容列表分页
  List = '/api/content/list',
  // 获取最新动态
  News = '/api/content/new',
  // 获取底部公众号图片
  Code = '/api/footer/mp-images',
  // 获取首页Banner
  Banner = '/api/homepage/banner',
  // 获取招聘职位信息
  Jobs = '/api/jobs',
  // 获取办公室环境图片
  WorkBannerList = '/api/image/list',
  // 获取根据id获取页面Banner
  BannerListById = '/cms/material/list_for_group/',
}
