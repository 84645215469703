import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a8099a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner-classify" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "classify-list" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.bannerUrl,
      alt: "",
      class: "banner"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classifyList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["classify-item", item.code === _ctx.activeCode ? 'active' : '']),
          key: item.code,
          onClick: ($event: any) => (_ctx.changeActiveCode(item.code))
        }, _toDisplayString(item.label), 11, _hoisted_4))
      }), 128))
    ])
  ]))
}