

import { defineComponent, reactive, watch, ref, onMounted, } from 'vue'

import  { useRouter } from 'vue-router'

import { useStore } from '@/store/index'

import {
  getCodeApi,
} from '@/api/api'

export default defineComponent({
  name: 'FooterInfo',
  setup () {
    const router = useRouter()
    const store = useStore()

    const state = reactive<{
      companyInfo: any
    }>({
      companyInfo: {}
    })

    watch(
      () => store.state.companyInfo,
      (val) => {
        state.companyInfo = val
      },
      {
        immediate: true
      }
    )

    const goToPage = (path: string, menu: string) => {
      store.commit('setCurrentActiveMenu', menu)
      router.push(path)
    }

    return {
      state,
      goToPage,
    }
  }
})
