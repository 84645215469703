import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4519150a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news-info" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "content-info" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "time-info" }
const _hoisted_8 = { class: "day" }
const _hoisted_9 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "news-item",
        key: item.id
      }, [
        _createElementVNode("img", {
          src: item.coverImage,
          alt: "",
          class: "cover"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(item.description), 1),
          _createElementVNode("div", {
            onClick: ($event: any) => (_ctx.handleGoDetail(item)),
            class: "btn"
          }, "了解更多>>", 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(item.day), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(item.time), 1)
        ])
      ]))
    }), 128))
  ]))
}