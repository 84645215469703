import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51cb3b9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  src: "/map.html",
  frameborder: "0",
  style: {"width":"100%","height":"440px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", _hoisted_1))
}