
import { defineComponent, onMounted, reactive, ref, nextTick } from "vue";
import { baseApiUrl } from "@/config/apiConfig";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";

export default defineComponent({
  name: "BannerList",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["onSwiper"],
  setup(props, { emit }) {
    const swiperRef = ref();

    const state = reactive<{
      path: string;
      swiper: any;
      list: any[];
    }>({
      path: "",
      swiper: null,
      list: [],
    });
    onMounted(() => {
      state.path = baseApiUrl;
    });
    const onSwiper = (swiper: any) => {
      swiperRef.value = swiper;
    };

    const onSlideChange = (swiper: any) => {
      if (!props.list.length) return;
      if (!swiperRef.value) return;
      console.log("123111", swiperRef.value);
      emit("onSwiper", swiperRef.value.realIndex);
    };
    const handleSlideSwiper = (type: string) => {
      if (type === "prev") {
        swiperRef.value.slidePrev();
      } else if (type === "next") {
        swiperRef.value.slideNext();
      }
    };

    return {
      state,
      onSwiper,
      onSlideChange,
      handleSlideSwiper,
    };
  },
});
