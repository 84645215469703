
import { defineComponent } from 'vue'
import { useRouter, useRoute } from 'vue-router'
export default defineComponent({
  name: 'TabInfo',
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const handleTab = (index: number) => {
      router.push({
        path: '/',
        query: { tab: index, code: route.query.code }
      })
    }
    return {
      handleTab
    }
  }
})
