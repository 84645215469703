
import { defineComponent, reactive, ref, watch, onMounted } from "vue";

import { useStore } from "@/store/index";

export default defineComponent({
  name: "RecruitInfo",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["onSwitchStatus"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive<{
      list: any[];
    }>({
      list: [],
    });

    watch(
      () => props.list,
      (val) => {
        state.list = val;
        console.log("state.list: ", state.list);
      },
      {
        immediate: true,
      }
    );

    const switchStatus = (data: any) => {
      if (data.jobResp) {
        window.open(data.jobResp);
      }

      // emit('onSwitchStatus', data)
    };

    return {
      state,
      switchStatus,
    };
  },
});
