

import { defineComponent } from 'vue'

import { useRouter } from 'vue-router'

export default defineComponent ({
  name: 'NewsInfo',
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  setup () {

    const router = useRouter()

    const handleGoDetail = (data: any) => {
      if (!data || !data.id) return false
      router.push({
        path: '/detail',
        query: {
          id: data.id,
          type:2
        }
      })
    }

    return {
      handleGoDetail,
    }
  }
})

