
import { addMsg } from '@/api/api'
import { defineComponent, reactive, toRaw, UnwrapRef, ref } from 'vue'
import {
  Popover,
  Form,
  FormItem,
  Button,
  Input,
  Textarea,
  message
} from 'ant-design-vue'
export default defineComponent({
  name: 'GoTop',
  emits: ['onGoTop'],
  setup(_, { emit }) {
    const goTop = () => {
      emit('onGoTop')
    }
    let visible = ref<any>(false)
    let formState = ref<any>({
      name: '',
      company: '',
      phone: '',
      email: '',
      remark: ''
    })
    const formRef = ref()
    const rules = {
      name: [
        {
          required: true,
          message: '您怎么称呼',
          trigger: 'blur'
        }
      ],
      phone: [
        {
          required: true,
          message: '您的联系电话',
          trigger: 'blur',
          validator: (rule: any, value: any) => {
            return new Promise((resolve: any, reject: any) => {
              // 手机号
              if (value.length == 11) {
                if (!/^1[3-9]\d{9}$/.test(value)) {
                  reject()
                } else {
                  resolve()
                }
              } else {
                // 座机
                if (!/^0\d{2,3}\-\d{7,8}$/.test(value)) {
                  reject()
                } else {
                  resolve()
                }
              }
            })
          }
        }
      ],
      company: [
        {
          required: true,
          message: '您的公司名称',
          trigger: 'blur'
        }
      ]
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }
    const onSubmit = () => {
      formRef.value.validate().then(() => {
        addMsg(formState._value).then((res) => {
          if (!res.data.code) {
            message.success({
              content: '提交成功，我们将尽快与您取得联系。'
            })
            formState = {
              name: '',
              company: '',
              phone: '',
              email: '',
              remark: ''
            }
            visible.value = false
          } else {
            message.error({
              content: '失败'
            })
          }
        })
      })
    }
    return {
      goTop,
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      formState,
      onSubmit,
      visible,
      rules,
      formRef
    }
  },
  components: {
    APopover: Popover,
    AForm: Form,
    AFormItem: FormItem,
    AInput: Input,
    AButton: Button,
    ATextarea: Textarea
  }
})
