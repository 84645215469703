
import { baseApiUrl } from '@/config/apiConfig'
import { defineComponent, onMounted, reactive, ref } from 'vue'

import Carousel from 'ant-design-vue/lib/carousel'
import 'ant-design-vue/lib/carousel/style/css'

import { getBannerApi, getBannerByIdApi } from '@/api/api'

export default defineComponent({
  name: 'BannerInfo',
  components: {
    Carousel
  },
  setup() {
    const carouselRef = ref()

    const state = reactive<{
      list: any[]
      path: string
    }>({
      list: [
        {
          id: '2',
          bannerUrl: 'shouye'
        }
      ],
      path: ''
    })

    onMounted(() => {
      state.path = baseApiUrl
      getBannerById()
    })

    const onChange = (current: number) => {
      // console.log(current)
    }
    const getBannerById = async () => {
      let res = await getBannerByIdApi(1)
      if (res.data.rows && res.data.rows.length) {
        state.list = res.data.rows
      }
    }
    const getBanner = async () => {
      try {
        const result = await getBannerApi()
        if (result.status !== 200 || !result.data) return false
        const { code, msg, data } = result.data
        if (code !== 0) {
          return false
        }
        state.list = data
      } catch (e) {
        console.log('出错', e)
      }
    }

    const handlePrev = () => {
      carouselRef.value.prev()
    }

    const handleNext = () => {
      carouselRef.value.next()
    }

    return {
      carouselRef,
      state,
      onChange,
      handlePrev,
      handleNext
    }
  }
})
