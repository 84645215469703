
import { defineComponent, watch, reactive, onMounted } from 'vue'

import { useRouter, useRoute } from 'vue-router'

import { useStore } from '@/store/index'

export default defineComponent({
  name: 'HeaderInfo',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const state = reactive({
      isHomeFirst: true,
      currentActiveMenu: '首页'
    })

    onMounted(() => {
      document.title = '四川润泽皓宇科技有限公司'
    })

    watch(
      () => route.path,
      (val) => {
        console.log('当前路由: ', val)
        document.documentElement.scrollTop = 0
        if (val === '/detail') {
          // 详情页
          store.commit('setIsHomeFirst', false)
          document.title = '四川润泽皓宇科技有限公司-详情'
        } else {
          let menu = ''
          if (val === '/') {
            menu = '首页'
            document.title = '四川润泽皓宇科技有限公司'
          } else if (val === '/product') {
            menu = '产品中心'
            document.title = '四川润泽皓宇科技有限公司-' + menu
          } else if (val === '/news') {
            menu = '行业动态'
            document.title = '四川润泽皓宇科技有限公司-' + menu
          } else if (val === '/detail?id=38') {
            menu = '项目案例'
            document.title = '四川润泽皓宇科技有限公司-' + menu
          } else if (val === '/scheme') {
            menu = '产品方案'
            document.title = '四川润泽皓宇科技有限公司-' + menu
          } else if (val === '/about') {
            menu = '关于我们'
            document.title = '四川润泽皓宇科技有限公司-' + menu
          } else if (val === '/cert') {
            menu = '荣誉资质'
            document.title = '四川润泽皓宇科技有限公司-' + menu
          }
          store.commit('setCurrentActiveMenu', menu)
        }
      },
      {
        immediate: true
      }
    )

    watch(
      () => store.state.isHomeFirst,
      (val) => {
        state.isHomeFirst = val
      },
      {
        immediate: true
      }
    )

    watch(
      () => store.state.currentActiveMenu,
      (val) => {
        state.currentActiveMenu = val
      },
      {
        immediate: true
      }
    )

    const goToPage = (path: string, menu: string) => {
      store.commit('setIsHomeFirst', path === '/')
      store.commit('setCurrentActiveMenu', menu)
      router.push(path)
    }

    return {
      state,
      goToPage
    }
  }
})
