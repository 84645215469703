import { defHttp } from '@/axios/index'
import { ApiConfig } from '@/config/apiConfig'

export function getCategorysApi() {
  return defHttp.get(
    {
      url: ApiConfig.Categorys
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function getCompanyApi() {
  return defHttp.get(
    {
      url: ApiConfig.Company
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function getDetailApi(id: string) {
  return defHttp.get(
    {
      url: ApiConfig.Detail + id
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function getListApi(params: any) {
  return defHttp.get(
    {
      url: ApiConfig.List,
      params
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function getNewsApi(params: any) {
  return defHttp.get(
    {
      url: ApiConfig.News,
      params
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function getCodeApi() {
  return defHttp.get(
    {
      url: ApiConfig.Code
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function getBannerApi() {
  return defHttp.get(
    {
      url: ApiConfig.Banner
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function getJobsApi() {
  return defHttp.get(
    {
      url: ApiConfig.Jobs
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function getWorkBannerListApi() {
  return defHttp.get(
    {
      url: ApiConfig.WorkBannerList
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function getBannerByIdApi(id: any) {
  return defHttp.get(
    {
      url: ApiConfig.BannerListById + id
    },
    {
      isReturnNativeResponse: true
    }
  )
}

export function addMsg(data: any) {
  return defHttp.post(
    {
      url: '/api/contact/add',
      data
    },
    {
      isReturnNativeResponse: true
    }
  )
}
