import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-674986e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-container" }
const _hoisted_2 = { class: "banner-item banner" }
const _hoisted_3 = { class: "banner-item" }
const _hoisted_4 = { class: "banner-item" }
const _hoisted_5 = { class: "banner-item" }
const _hoisted_6 = { class: "banner-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerInfo = _resolveComponent("BannerInfo")!
  const _component_ProductCenter = _resolveComponent("ProductCenter")!
  const _component_SolutionInfo = _resolveComponent("SolutionInfo")!
  const _component_LatestNews = _resolveComponent("LatestNews")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_CopyrightInfo = _resolveComponent("CopyrightInfo")!
  const _component_GoTop = _resolveComponent("GoTop")!
  const _component_TabInfo = _resolveComponent("TabInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Carousel, {
      class: "banner-container",
      "after-change": _ctx.onChange,
      dots: false,
      "dot-position": "right",
      ref: "carouselRef"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BannerInfo)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ProductCenter, {
            list: _ctx.state.productList
          }, null, 8, ["list"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SolutionInfo, {
            list: _ctx.state.schemeList
          }, null, 8, ["list"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_LatestNews, {
            list: _ctx.state.newsList
          }, null, 8, ["list"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AboutUs)
        ])
      ]),
      _: 1
    }, 8, ["after-change"]),
    _createVNode(_component_CopyrightInfo, { class: "copyright-comp" }),
    _withDirectives(_createVNode(_component_GoTop, { onOnGoTop: _ctx.handleGoTop }, null, 8, ["onOnGoTop"]), [
      [_vShow, _ctx.state.current !== 0]
    ]),
    _withDirectives(_createVNode(_component_TabInfo, {
      activeIndex: _ctx.state.current - 1
    }, null, 8, ["activeIndex"]), [
      [_vShow, _ctx.state.current !== 0]
    ])
  ]))
}